<template>
  <BlokkliProvider v-slot="{ entity }" v-bind="blokkliProps" :entity="props">   
    <BlokkliField :list="paragraphs" name="field_icms_paragraphs" />
  </BlokkliProvider>
</template>

<script lang="ts" setup>
import type { NodeIcmsPageFragment } from '#graphql-operations'

const props = defineProps<{
  uuid?: string
  title?: string
  paragraphs?: NodeIcmsPageFragment['paragraphs']
  blokkliProps: NodeIcmsPageFragment['blokkliProps']
}>()

</script>
